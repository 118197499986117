import React from 'react';
import MissionVideo from "../../videos/mission.webm";
import {useTranslation} from "react-i18next";

const OurMission = () => {
  const {t} = useTranslation();

  return (
    <section className="our-mission">
      <div className="our-mission__wrapper wrapper">
        <h2 className="big-title out-mission__title" dangerouslySetInnerHTML={{__html:t("ourMission.title")}}/>
        <div className="our-mission__content">
          <div className="our-mission__video-wrapper">
            <video className="our-mission__video-item" playsInline autoPlay loop muted>
              <source src={MissionVideo} type="video/webm"/>
            </video>
          </div>
          <div className="our-mission__text-wrapper">
            <p>{t("ourMission.text")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMission;
