import React from "react";
import LeadVideoItem from "../../videos/lead.webm";

const LeadVideo = () => {
  return (
    <section className="lead-video">
      <div className="lead-video__wrapper wrapper">
        <div className="lead-video__content-wrapper">
          <video className="lead-video__item" playsInline autoPlay loop muted>
            <source src={LeadVideoItem} type="video/webm"/>
          </video>
        </div>
      </div>
    </section>
  );
};

export default LeadVideo;
